"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class LikeInstancesStorage {
    constructor() {
        this.instances = {};
    }
    // Returns all instances of a like button with a given postId
    getInstances(postId) {
        return this.instances[postId] || [];
    }
    // Adds a new instance of a like button with a given
    addInstance(postId, likeInstance) {
        if (!this.instances[postId]) {
            this.instances[postId] = [];
        }
        this.instances[postId].push(likeInstance);
    }
}
exports.default = LikeInstancesStorage;
