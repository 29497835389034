"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeLikeButtons = initializeLikeButtons;
const likeIconSelector = '[data-like-icon]';
const likedIconClass = 'material-symbols--filled';
const postIdAttribute = 'data-post-id';
const postTypeAttribute = 'data-post-type';
const tooltipAttribute = 'data-tooltip';
const iconWrapperAttribute = 'data-js-like-icon-wrapper';
class Like {
    constructor(likeStorage, likeInstancesStorage, button, wrapper, postId, postType, tooltipLike, tooltipUnlike) {
        this.likeStorage = likeStorage;
        this.likeInstancesStorage = likeInstancesStorage;
        this.button = button;
        this.wrapper = wrapper;
        this.postId = postId;
        this.postType = postType;
        this.tooltipLike = tooltipLike;
        this.tooltipUnlike = tooltipUnlike;
        this.hasTooltip = Boolean(this.wrapper && (!!this.tooltipLike || !!this.tooltipUnlike));
        this.updateLikedStatus();
        this.setTooltip();
        this.setListener();
    }
    // Sets the event listener for the like button
    setListener() {
        this.button.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.likeStorage.set(this.postId, this.postType);
            this.toggleLiked();
            window.dispatchEvent(this.likedPostsUpdatedEvent());
        });
    }
    // Sets the tooltip for the like button
    setTooltip() {
        if (!this.hasTooltip) {
            return;
        }
        const isLiked = this.likeStorage.get()[this.postId];
        if (isLiked) {
            if (this.tooltipUnlike) {
                this.wrapper.setAttribute(tooltipAttribute, this.tooltipUnlike);
            }
            else {
                this.wrapper.removeAttribute(tooltipAttribute);
            }
        }
        else {
            if (this.tooltipLike) {
                this.wrapper.setAttribute(tooltipAttribute, this.tooltipLike);
            }
            else {
                this.wrapper.removeAttribute(tooltipAttribute);
            }
        }
    }
    // Toggles the like button (liked/not liked) and updates all like buttons with the same postId
    toggleLiked() {
        this.likeInstancesStorage.getInstances(this.postId).forEach((instance) => {
            instance.updateLikedStatus();
            instance.setTooltip();
        });
    }
    // Updates the state of the like button (liked/not liked)
    updateLikedStatus() {
        const isLiked = this.likeStorage.get()[this.postId];
        if (isLiked) {
            this.button.classList.add(likedIconClass);
        }
        else {
            this.button.classList.remove(likedIconClass);
        }
    }
    // Custom event to update liked posts length
    likedPostsUpdatedEvent() {
        return new CustomEvent('likedPostsLengthUpdated', {});
    }
}
exports.default = Like;
// Initialize like buttons/icons
function initializeLikeButtons(likeStorage, likeInstancesStorage, tooltipLike, tooltipUnlike) {
    const createLikeInstance = (button) => {
        const postId = button.getAttribute(postIdAttribute);
        const postType = button.getAttribute(postTypeAttribute);
        if (!postId || !postType) {
            return;
        }
        const wrapper = button.closest(`[${iconWrapperAttribute}]`);
        likeInstancesStorage.addInstance(postId, new Like(likeStorage, likeInstancesStorage, button, wrapper, postId, postType, tooltipLike, tooltipUnlike));
    };
    document.querySelectorAll(likeIconSelector).forEach((button) => {
        createLikeInstance(button);
    });
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                [...mutation.addedNodes].forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const element = node;
                        if (element.matches(likeIconSelector)) {
                            createLikeInstance(element);
                        }
                        element.querySelectorAll(likeIconSelector).forEach((button) => {
                            createLikeInstance(button);
                        });
                    }
                });
            }
        });
    });
    observer.observe(document.body, { childList: true, subtree: true });
}
